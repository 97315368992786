<!-- 我的挂单详情页 -->
<script setup>
import { provider, ethers, signer } from '@/utils/ethers';
import { computed, reactive, ref, provide } from 'vue';
import { useAppStore } from '@store/appStore';
import MarketDetail from '@cps/MarketDetailComp';
import useMarketStore from '@store/cattleMarketStore';
import useCattleMintStore from '@contractStore/cattleMintStore';
import {
  hasParents,
  cattleName,
  cattleDesc,
  cattleImg,
  cattleInfo,
  fatherInfo,
  motherInfo,
  cattleDeadTime,
  setBlindBoxParents,
  setCattleDetailInfo
} from './useListingDetail.js';
import {
  BVT_TOKEN_CONT,
  BVG_TOKEN_CONT,
  USDT_TOKEN_CONT,
  CATTLE_CONT,
  STAR_CONT,
  NFT_MINT_CONT,
  BLIND_BOX_CONT as POLYGON_BLIND_BOX_CONT
} from '@/contracts/polygonAddress.js';
import { useRoute, useRouter } from 'vue-router';
import CancelPop from '@cps/MarketDetailComp/components/CancelPop.vue';
import { useI18n } from 'vue-i18n';
import { ElMessage } from 'element-plus';
import $load from '@cps/GlobalLoading';
import CoinToken from '@/contracts/CoinToken.js';
import useCattleStore from '@contractStore/cattleStore';
import usePlanetStore from '@contractStore/planetStore';
import useBlindBoxStore from '@contractStore/blindBoxStore';

const $tx = useI18n();
const $route = useRoute();
const $router = useRouter();

// 要展示的类型: blindBox、calf、planet
const showCate = computed(() => {
  if ($route.query.type === 'hero' || $route.query.type === 'cattle') {
    return 'calf';
  } else {
    return $route.query.type;
  }
});

const appStore = useAppStore();
const cattleStore = useCattleStore();
const cattleMintStore = useCattleMintStore();
const planetStore = usePlanetStore();
const blindBoxStore = useBlindBoxStore();
cattleStore.createContract();
planetStore.createContract();
cattleMintStore.createContract();
/**
 * 构建代币对象
 */
let tokenObj;
if ($route.query.tradeType === '1') {
  tokenObj = new CoinToken(BVT_TOKEN_CONT);
} else if ($route.query.tradeType === '2') {
  tokenObj = new CoinToken(BVG_TOKEN_CONT);
} else if ($route.query.tradeType === '4') {
  tokenObj = new CoinToken(USDT_TOKEN_CONT);
}

/**
 * 获取牛牛详情
 */
const hasAllowance = ref(false);
const goodsType = ref(1);
(async () => {
  if (appStore.defaultAccount == null) return;
  try {
    await isApproveToken();
    if (showCate.value === 'calf') {
      goodsType.value = 1;
      await setCattleDetailInfo($route.query.id);
    } else if (showCate.value === 'planet') {
      goodsType.value = 3;
      await planetStore.getPlanetInfo($route.query.id, 1);
    } else if (showCate.value === 'blindBox') {
      goodsType.value = 2;
      // blindBoxStore.getBoxInfo($route.query.id, $route.query.type);
      const pids = await blindBoxStore.checkParents($route.query.id);
      setBlindBoxParents(pids);
    }
  } catch (error) {
    console.log('setListing detail..', error);
    // ElMessage({
    //   message: error,
    //   type: 'error'
    // });
  }
})();

async function isApproveToken() {
  // 判断是否授权
  if ($route.query.tradeType === '3') {
    hasAllowance.value = true;
  } else {
    hasAllowance.value = await tokenObj?.allow(appStore.defaultAccount, NFT_MINT_CONT.address);
  }
}

async function setApproveToken() {
  $load({ isShow: true });
  // 判断是否授权
  if ($route.query.tradeType === '3') {
    hasAllowance.value = true;
  } else {
    const res = await tokenObj.auth(NFT_MINT_CONT.address);
    await res?.wait();
    hasAllowance.value = await tokenObj?.allow(appStore.defaultAccount, NFT_MINT_CONT.address);
  }
  $load({ isShow: false });
}

// 控制订单弹窗
const showCancelPop = ref(false);

/**
 * 立即开启
 */
function handleOpenPop() {
  showCancelPop.value = true;
}

/**
 * 取消订单
 */
async function orderCancel() {
  $load({ isShow: true });
  const goodsType = { calf: 1, blindBox: 2, planet: 3 };
  try {
    await cattleMintStore.cancelSell({
      goodsType: goodsType[showCate.value],
      id: $route.query.id
    });
    ElMessage({
      message: $tx.t('msg.24'),
      type: 'success'
    });
    showCancelPop.value = false;
    $router.push({ name: 'nftMarket' });
  } catch (error) {
    ElMessage({
      message: $tx.t('msg.25'),
      type: 'error'
    });
    console.log('order cancel..', error);
  }
  $load({ isShow: false });
}

// 分发牛牛属性
provide(
  'hasParents',
  computed(() => hasParents.value)
);

// 分发牛牛属性
provide(
  'cattleAttr',
  computed(() => cattleInfo.value)
);

// 分发公牛属性
provide(
  'fatherAttr',
  computed(() => fatherInfo.value)
);

// 分发母牛属性
provide(
  'motherAttr',
  computed(() => motherInfo.value)
);

// 分发小牛的死亡时间
provide(
  'cattleDeadTime',
  computed(() => cattleDeadTime.value)
);

// 分发星球属性
provide(
  'planetAttr',
  computed(() => planetStore.planetInfo)
);


// 分发名称
provide(
  'cardName',
  computed(() => {
    if (showCate.value === 'calf') {
      return cattleName.value;
    } else if (showCate.value === 'planet') {
      return $tx.t(planetStore.planetInfo.name);
    } else if (showCate.value === 'blindBox') {
      return $tx.t(blindBoxStore.blindBoxInfo?.name);
    } else {
      return 'N/A';
    }
  })
);

// 分发描述信息
provide(
  'desc',
  computed(() => {
    if (showCate.value === 'calf') {
      return cattleDesc.value;
    } else if (showCate.value === 'planet') {
      // 星球
      return $tx.t(planetStore.planetInfo.desc);
    } else if (showCate.value === 'blindBox') {
      // 盲盒
      return $tx.t(blindBoxStore.blindBoxInfo?.desc);
    }
  })
);

// 分发图片
provide(
  'cardImg',
  computed(() => {
    if (showCate.value === 'calf') {
      return cattleImg.value;
    } else if (showCate.value === 'planet') {
      return 'https://game.legendranch.app/legendaryranch/planet/homeland.png';
    } else if (showCate.value === 'blindBox') {
      return 'https://game.legendranch.app/legendaryranch/boxgif/box.png';
    } else {
      return 'https://s3.bmp.ovh/imgs/2022/01/eac36c3b71fe903c.png';
    }
  })
);

// 分发 合约地址
provide(
  'contractAddr',
  computed(() => {
    if (showCate.value === 'planet') {
      // 星球
      return STAR_CONT.address;
    } else if (showCate.value === 'calf') {
      // 牛
      return CATTLE_CONT.address;
    } else if ($route.query.type === 'blindBox') {
      // 盲盒
      return POLYGON_BLIND_BOX_CONT.address;
    } else {
      return 'N/A';
    }
  })
);

// 分发tokenId
provide('tokenId', $route.query.id);

// 分发 ownerAddr
provide('ownerAddr', appStore.defaultAccount);

// price
provide('priceObj', {
  price: $route.query.price,
  doller: $route.query.doller,
  tradeType: $route.query.tradeType
});
</script>

<template>
  <MarketDetail :showCate="showCate" @btn="showCancelPop = true">
    <template #btn>
      <button class="buy-btn" @click="handleOpenPop">{{ $t('detail.27') }}</button>
    </template>
  </MarketDetail>
  <!-- 弹窗 -->
  <van-popup v-model:show="showCancelPop">
    <CancelPop :isApprove="hasAllowance" @confirm="orderCancel" @cancel="showCancelPop = false" />
  </van-popup>
</template>

<style lang="scss" scoped></style>
