import { watch, watchEffect, computed, reactive, ref, readonly } from 'vue';
import { useI18n } from 'vue-i18n';
import { useAppStore } from '@store/appStore';
import { padNumber } from '@/utils/tools.js';
import dayjs from 'dayjs';
import useCattleStore from '@contractStore/cattleStore';
import useMarketStore from '@store/cattleMarketStore';

/**
 * 设置牛牛详情信息
 */
const cattleAttrInfo = ref({
  cls: 0,
  parents: [0, 0],
  isAdult: 0,
  gender: 0,
  life: 0,
  growth: 0,
  energy: 0,
  attack: 0,
  stamina: 0,
  defense: 0,
  milk: 0,
  milkRate: 0
});
const cattleParents = ref([]);
const cattleDetailInfo = ref({});
const tokenId = ref(1);
export async function setCattleDetailInfo(id) {
  tokenId.value = id;
  const marketStore = useMarketStore();
  const cattleStore = useCattleStore();
  cattleDetailInfo.value = await marketStore.fetchCattleInfo(id);
  await cattleStore.getCattleInfo(id);
  cattleAttrInfo.value = cattleStore.cattleInfo;
  const { parents } = cattleAttrInfo.value;
  cattleParents.value = parents;
}

/**
 * 设置盲盒父母
 */
export function setBlindBoxParents(val) {
  cattleParents.value = val;
}

/**
 * 牛牛的名称
 */
const cname = computed(() => {
  const $tx = useI18n();
  const { name, class: cls, gender } = cattleDetailInfo.value;
  if (cls === 1) {
    return `${$tx.t('card.3')} #${tokenId.value}`;
  } else if (cls === 2) {
    return `${$tx.t('card.4')} #${tokenId.value}`;
  }
  return 'N/A';
});
export const cattleName = readonly(cname);

/**
 * 牛牛的簡介
 */
const cdesc = computed(() => {
  const $tx = useI18n();
  const { name, class: cls, gender } = cattleDetailInfo.value;
  if (cls === 1) {
    return `${$tx.t('card.34')}`;
  } else if (cls === 2) {
    return `${$tx.t('card.35')}`;
  }
  return 'N/A';
});
export const cattleDesc = readonly(cdesc);

/**
 * 牛牛的图片
 */
const cimg = computed(() => {
  const { image } = cattleDetailInfo.value;
  return image;
});
export const cattleImg = readonly(cimg);

/**
 * 牛牛的死亡时间
 */
 const cdateTime = computed(() => {
  const { deadTime } = cattleAttrInfo.value;
  const { class: cls } = cattleDetailInfo.value;
  if (cls === 1) {
    return null;
  } else if (cls === 2) {
    return deadTime;
  }
  return null;
});
export const cattleDeadTime = readonly(cdateTime);

// 基础属性
const basicAttr = reactive({
  class: 'detail.2',
  info: [
    {
      name: 'detail.5',
      eName: 'life',
      value: '-'
    },
    {
      name: 'detail.6',
      eName: 'growth',
      value: '-'
    },
    {
      name: 'detail.7',
      eName: 'str',
      value: '-'
    }
  ]
});

// 战斗属性
const battleAttr = reactive({
  class: 'detail.3',
  info: [
    {
      name: 'detail.8',
      eName: 'attack',
      value: '-'
    },
    {
      name: 'detail.9',
      eName: 'stamina',
      value: '-'
    },
    {
      name: 'detail.10',
      eName: 'defence',
      value: '-'
    }
  ]
});

// 产奶属性
const milkAttr = reactive({
  class: 'detail.4',
  info: [
    {
      name: 'detail.11',
      eName: 'milk',
      value: '-'
    },
    {
      name: 'detail.12',
      eName: 'milk-rate',
      value: '-'
    }
  ]
});

// 最终要展示的属性
const finalAttr = computed(() => {
  const {
    cls,
    parents,
    isAdult,
    gender,
    life,
    growth,
    energy,
    attack,
    stamina,
    defense,
    milk,
    milkRate
  } = cattleAttrInfo.value;

  if (new Set(parents).has(0)) {
    basicAttr.parents = null; // 父母id
  } else {
    basicAttr.parents = parents; // 父母id
  }
  // basicAttr.parents = [1, 20];
  var _ = require('lodash');
  const tempBasicAttr = _.cloneDeep(basicAttr);
  tempBasicAttr.gender = gender; // 性别
  tempBasicAttr.info[0].value = life; // 生命值
  tempBasicAttr.info[1].value = growth; // 成长值
  tempBasicAttr.info[2].value = energy; // 体力值

  // 公牛
  if (gender === 1) {
    const tempBattleAttr = _.cloneDeep(battleAttr);
    tempBattleAttr.info[0].value = attack.toFixed(0); // 攻击
    tempBattleAttr.info[1].value = stamina.toFixed(0); // 耐力
    tempBattleAttr.info[2].value = defense.toFixed(0); // 防御

    return [tempBasicAttr, tempBattleAttr];
  }
  // 母牛
  if (gender === 2) {
    const tempMilkAttr = _.cloneDeep(milkAttr);
    tempMilkAttr.info[0].value = milk.toFixed(0);
    tempMilkAttr.info[1].value = milkRate.toFixed(0);

    return [tempBasicAttr, tempMilkAttr];
  }

  return [];
});

export const cattleInfo = readonly(finalAttr);

/**
 * 是否存在父母
 */
const hasParentsId = ref(false);
const hasCattleParents = computed(() => {
  return !hasParentsId.value && cattleParents.value?.length;
});
export const hasParents = readonly(hasCattleParents);

watchEffect(async () => {
  hasParentsId.value = new Set(cattleParents.value).has(0);
  console.log('parents..', hasParents.value, cattleParents.value, cattleParents.value?.length);
  if (hasParents.value) {
    await getAllParentData();
  }
});

async function getAllParentData() {
  // 构造 父母属性 数组
  await Promise.all([
    getParentDetailInfo(cattleParents.value[0]),
    getParentDetailInfo(cattleParents.value[1])
  ]).then(values => {
    values.forEach(item => {
      if (item?.gender == 1) {
        bullAttr.value = integrateData(item);
      } else if (item?.gender == 2) {
        cowAttr.value = integrateData(item);
      }
    });
    // console.log('parent...', bullAttr.value, cowAttr.value);
  });
}

/**
 * 公牛属性
 */
const bullAttr = ref(null);
const fatherAttr = computed(() => {
  return bullAttr.value;
});
export const fatherInfo = readonly(fatherAttr);

/**
 * 母牛属性
 */
const cowAttr = ref(null);
const motherAttr = computed(() => {
  return cowAttr.value;
});
export const motherInfo = readonly(motherAttr);

/**
 * 获取父母的信息
 */
 const parentDetailInfo = ref({});
 async function getParentDetailInfo(id) {
   const cattleStore = useCattleStore();
   const infos = await cattleStore.getCattleInfo(id);
   return infos;
 }

/**
 * 整合属性 深拷贝
 * @param {Object} infos
 * @returns Array
 */
function integrateData(infos) {
  const { gender, life, growth, energy, attack, stamina, defense, milk, milkRate } = infos;
  var _ = require('lodash');
  const tempBasicAttr = _.cloneDeep(basicAttr);
  tempBasicAttr.gender = gender; // 性别
  tempBasicAttr.info[0].value = life; // 生命值
  tempBasicAttr.info[1].value = growth; // 成长值
  tempBasicAttr.info[2].value = energy; // 体力值
  console.log('info..', tempBasicAttr, infos);

  // 公牛
  const tempBattleAttr = _.cloneDeep(battleAttr);
  if (gender === 1) {
    tempBattleAttr.info[0].value = attack; // 攻击
    tempBattleAttr.info[1].value = stamina; // 耐力
    tempBattleAttr.info[2].value = defense; // 防御
    return [tempBasicAttr, tempBattleAttr];
  }
  // 母牛
  const tempMilkAttr = _.cloneDeep(milkAttr);
  if (gender === 2) {
    tempMilkAttr.info[0].value = milk;
    tempMilkAttr.info[1].value = milkRate;
    return [tempBasicAttr, tempMilkAttr];
  }

  return [];
}
